import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyles = makeStyles(() => ({
  accordionTitle: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontSize: 18,
      fontWeight: 500,
    },
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  accordion: {
    "&.Mui-expanded": {
      transform: "rotate(0deg) !important",
    },
  },
  divRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "20px",
    [theme.breakpoints.down(470)]: {
      gap: "0px",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down(860)]: {
      justifyContent: "space-between",
    },
  },

  labelAndInput: {
    display: "flex",
    flexDirection: "column",
  },
}));
