import api from "./api";
interface ILogIn {
  email: string;
  password: string;
}

const UsersService = {
  create: async (body: any) =>
    await api.post("/api/user/onboarding/register", { ...body }),
};

export default UsersService;
