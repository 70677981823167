import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyles = makeStyles(() => ({
  container: {
    minWidth: "30%",
    height: "100%",
    maxWidth: "300px",
    minHeight: "170px",
    borderRadius: "5px",
    border: "1px solid #CCC",
    background: " #FFF",
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 10px",
  },
  topInformation: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontWeight: 700,
    },
  },
  information: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  checkbox: {
    width: "25px",
    height: "25px",
    backgroundColor: theme.palette.text.primary,
  },
}));
