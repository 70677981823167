import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100%",
    position: "relative",
  },
  header: {
    width: "100%",
    height: "80px",
    padding: "9px 0px",
    backgroundColor: "#FFF",

    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down(860)]: {
      height: "190px", //
    },
  },

  wrapperInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up(860)]: {
      justifyContent: "space-around",
    },
  },
  containerSteps: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down(860)]: {
      display: "none", //
    },
  },
  containerCircle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "15px",
    [theme.breakpoints.down(1001)]: {
      gap: "10px",
    },
  },
  circle: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  circleOutlined: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  circleTextOutlined: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 700,
    },
  },
  circleText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontSize: 16,
      fontWeight: 700,
    },
  },
  textStep: {
    "&.MuiTypography-root": {
      color: "#333",
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down(1001)]: {
        fontSize: 14, //
      },
    },
  },
  logo: {
    width: "100px",
    height: "auto",
    maxHeight: "70px",
  },
  retangleOrder: {
    height: "auto",
    borderRadius: "20px",
    background: "#F8F8F8",
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up(860)]: {
      display: "none", //
    },
  },
  textOrder: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 12,
      fontWeight: 700,
    },
  },
  textSecurity: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px",
    "&.MuiTypography-root": {
      marginRight: "30px",
      color: theme.palette.text.secondary,
      fontSize: 11,
      fontWeight: "600",
      textAlign: "end",
    },
  },
  containerEnvinroment: {
    [theme.breakpoints.up(860)]: {
      display: "none", //
    },
  },

  divRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: '20px',
    [theme.breakpoints.down(470)]: {
      gap: '0px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(860)]: {
      justifyContent: 'space-between',
    },
  },

  labelAndInput: {
    display: "flex",
    flexDirection: "column",
  },

  wrapperStep: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(860)]: {
      display: "none", //
    },
  },
  title: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.dark,
      fontSize: 20,
      fontWeight: 600,
      margin: "25px 0px 0px 20px",
      [theme.breakpoints.up(860)]: {
        margin: "25px 0px 0px 60px",
      },
    },
  },
  containerColumns: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(860)]: {
      flexDirection: "row",
    },
  },
  box: {
    margin: "10px",
    backgroundColor: "#FFF",
    boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "20px 20px",
    [theme.breakpoints.up(860)]: {
      width: "60%",
      margin: "10px 20px 10px 60px ",
    },
  },
  boxTitle: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  separator: {
    width: "90%",
    border: "1px dashed #CCC",
    marginTop: "20px",
    marginBottom: "20px",
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  button: {
    width: "100%",
    height: "80px",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    [theme.breakpoints.up(860)]: {
      "& .MuiButtonBase-root": {
        width: "430px",
        heigth: "40px",
      },
    },
  },
  observation: {
    "&.MuiTypography-root": {
      color: "rgba(80, 95, 113, 0.60)",
      fontSize: 9,
      fontWeight: 500,
      margin: "15px 0px 10px 0px",
    },
  },
  titleHolder: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 17,
      fontWeight: 600,
    },
  },
}));
