import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#51C6D6",
      dark: "#08559F",
    },
    text: {
      primary: "#505F71",
      secondary: "#333333",
      
    },
  },
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
});

export default theme;
