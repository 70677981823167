export const data = {
  name: "Felipe Martins",
  cpf: "343.212.223-21",
  phone: "(15) 9.3212-2123",
  email: "felipe@lionsoft.com.br",
  address: "Rua Dr. Jair Coronel da Silva, 212 - Sorocaba / SP",
  cep: "CEP: 28123-212",
  dependents: "2 pessoas (R$ 29,90)",
  plan: "Pagamento: mensal (recorrente)",
  paymentType: "Cartão de crédito (mastercard)",
  paymentDate: "mensal dia 22",
  total: "R$ 29,90",
};

export const plans = [
  {
    dependents: 2,
    value: '29,90'
  },
  {
    dependents: 3,
    value: '39,90'
  },
  {
    dependents: 4,
    value: '49,90'
  }
]