import { useStyles } from "./styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import theme from "../../theme";
import { Box, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CpfMask, PhoneMask, dateMask } from "../../utils/masks";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
interface IDependentOne {
  body: any;
  setBody: any;
  setValidationFormTwo: (value: boolean) => void;
}

export function AccordionDependentTwo({
  body,
  setBody,
  setValidationFormTwo,
}: IDependentOne) {
  const classes = useStyles();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const hasEmptyFields = (data: any) => {
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim() === "") {
        return true;
      }
    }
    return false;
  };

  const searchPostalCode = async () => {
    if (body?.address?.zip_code && body?.address?.zip_code.length === 8) {
      fetch(`https://viacep.com.br/ws/${body?.address?.zip_code}/json/`)
        .then((response) => response.json())
        .then((data) => {
          if (data.erro !== "true" || data.erro !== true) {
            setBody((prev: any) => ({
              ...prev,
              address: {
                ...body?.address,
                zip_code: data.cep,
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf,
              },
            }));
          }
        });
    }
  };

  const isDataValid = !hasEmptyFields(body);
  const isAddressValid = !hasEmptyFields(body?.address);

  useEffect(() => {
    if (isDataValid && isAddressValid) {
      setIsCompleted(true);
      setValidationFormTwo(true);
    } else {
      setIsCompleted(false);
      setValidationFormTwo(false);
    }
  }, [isDataValid, isAddressValid]);

  useEffect(() => {
    searchPostalCode();
  }, [body?.address?.zip_code]);

  return (
    <Accordion
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <AccordionSummary
        className={classes.accordion}
        expandIcon={
          isCompleted ? (
            <CheckCircleIcon sx={{ color: "white", borderRadius: "10px" }} />
          ) : (
            <CancelIcon sx={{ color: "red", borderRadius: "10px" }} />
          )
        }
      >
        <Typography className={classes.accordionTitle}>
          Dependente adicional - n.° 2
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: "white",
          border: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        <form>
          <label className={classes.label}>Nome completo</label>
          <TextField
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "20px" }}
            value={body?.name}
            onChange={(e) => setBody({ ...body, name: e.target.value })}
          />
          <Box className={classes.divRow}>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Data nascimento</label>
              <TextField
                name="birthday"
                variant="outlined"
                size="small"
                fullWidth
                style={{
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
                value={dateMask(body?.birthday) ?? body?.birthday}
                onChange={(e) => setBody({ ...body, birthday: e.target.value })}
                inputProps={{ maxLength: 10 }}
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>CPF</label>
              <TextField
                name="cpf"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: "5px", marginBottom: "20px" }}
                value={CpfMask(body?.cpf) ?? body?.cpf}
                onChange={(e) => setBody({ ...body, cpf: e.target.value })}
                inputProps={{ maxLength: 14 }}
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Telefone de contato</label>
              <TextField
                name="phone"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: "5px", marginBottom: "20px" }}
                value={PhoneMask(body?.phone) ?? body?.phone}
                onChange={(e) => setBody({ ...body, phone: e.target.value })}
              />
            </Box>
          </Box>
          <label className={classes.label}>E-mail</label>
          <TextField
            name="email"
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "20px" }}
            value={body?.email}
            onChange={(e) => setBody({ ...body, email: e.target.value })}
          />
          <label className={classes.label}>CEP</label>
          <TextField
            name="cep"
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "20px" }}
            value={body?.address?.zip_code}
            onChange={(e) =>
              setBody({
                ...body,
                address: { ...body.address, zip_code: e.target.value },
              })
            }
            inputProps={{ maxLength: 9 }}
          />
          <label className={classes.label}>Endereço</label>
          <TextField
            name="address"
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "20px" }}
            value={body?.address?.street}
            onChange={(e) =>
              setBody({
                ...body,
                address: { ...body.address, street: e.target.value },
              })
            }
          />
          <Box className={classes.divRow}>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Bairro</label>
              <TextField
                name="neighborhood"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: "5px", marginBottom: "20px" }}
                value={body?.address?.neighborhood}
                onChange={(e) =>
                  setBody({
                    ...body,
                    address: { ...body.address, neighborhood: e.target.value },
                  })
                }
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Número</label>
              <TextField
                name="number"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: "5px", marginBottom: "20px" }}
                value={body?.address?.number}
                onChange={(e) =>
                  setBody({
                    ...body,
                    address: { ...body.address, number: e.target.value },
                  })
                }
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Complemento</label>
              <TextField
                name="complement"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: "5px", marginBottom: "20px" }}
                value={body?.address?.complement}
                onChange={(e) =>
                  setBody({
                    ...body,
                    address: { ...body.address, complement: e.target.value },
                  })
                }
              />
            </Box>
          </Box>
          <label className={classes.label}>Cidade</label>
          <TextField
            name="city"
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "20px" }}
            value={body?.address?.city}
            onChange={(e) =>
              setBody({
                ...body,
                address: { ...body.address, city: e.target.value },
              })
            }
          />
        </form>
      </AccordionDetails>
    </Accordion>
  );
}
