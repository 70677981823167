import { Box, Typography, } from "@mui/material";
import { useStyles } from "./style";
import twoPeople from '../../assets/images/duas-pessoas.png'
import threePeople from '../../assets/images/tres-pessoas.png'
import fourPeople from '../../assets/images/quatro-pessoas.png'
import checked from '../../assets/images/checked.svg'
interface CardProps {
  dependents: number
  value: string
  dependentsSelected: number;
  setDependentsSelected: (value: number) => void;
  setValueSelected: (value: string) => void;
}

export function Card({ dependents, value, dependentsSelected, setDependentsSelected, setValueSelected }: CardProps) {
  const classes = useStyles();
  const isChecked = dependents === dependentsSelected

  const changeImage = () => {
    let image;

    if (dependents === 2) {
      image = twoPeople
    }
    if (dependents === 3) {
      image = threePeople
    }
    if (dependents === 4) {
      image = fourPeople
    }
    return image;
  };

  return (

    <Box className={classes.container} onClick={() => { setDependentsSelected(dependents); setValueSelected(value) }} style={{
      boxShadow: isChecked ? '0px 0px 10px 0px rgba(0, 0, 0, 0.60)' : 'none'
    }} >
      <Box className={classes.content}>
        <Box className={classes.topInformation}>
          {isChecked ?
            <img src={checked} alt='icone de selecionado' />
            :
            <Box />
          }
          <img src={changeImage()} alt="dependentes" />
        </Box>
        <Box>

          <Typography className={classes.title}>{dependents} pessoas</Typography>
          <Typography className={classes.information}>R$ {value}</Typography>
        </Box>
      </Box>

    </Box >
  );
}
