// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Activation } from "./pages/Activation";
import { Registration } from "./pages/Registration";
import { Payment } from "./pages/Payment";

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Activation />} />
          <Route path="/registro" element={<Registration />} />
          <Route path="/pagamento" element={<Payment />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
