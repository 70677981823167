import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import logo from "../../assets/images/logo-drsaude.png";
import mobileSecondStep from "../../assets/images/second-step-mobile.png";
import flagCards from "../../assets/images/payments.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HttpsIcon from "@mui/icons-material/Https";
import { useStyles } from "./styles";
import theme from "../../theme";
import { AccordionDependent } from "../../components/AccordionOne";
import { AccordionDependentTwo } from "../../components/AccordionTwo";
import { AccordionDependentThree } from "../../components/AccordionThree";
import { DesktopInformations } from "../../components/DesktopInformations";
import { Paper } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { RequestedSummary } from "../../components/RequestedSummary";
import CheckIcon from "@mui/icons-material/Check";
import { plans } from "../../mocks/planInformations";
import { Card } from "../../components/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCreditCardNumber } from "../../utils/masks";
import UsersService from "../../services/UsersService";
import Swal from "sweetalert2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Registration() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { state } = useLocation();
  const { data, manager_code } = state;
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dependentsSelected, setDependentsSelected] = useState<number>(2);
  const [valueSelected, setValueSelected] = useState<string>("29.90");
  const [validationFormOne, setValidationFormOne] = useState<boolean>(false);
  const [validationFormTwo, setValidationFormTwo] = useState<boolean>(false);
  const [validationFormThree, setValidationFormThree] =
    useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const navigate = useNavigate();

  const [payment, setPayment] = useState({
    card_holder_name: "",
    card_brand: "",
    card_cvv: "",
    card_year_expiration: "",
    card_month_expiration: "",
    card_number: "",
    plan_code: "plan1",
    manager_code: manager_code,
  });
  const [bodyOne, setBodyOne] = useState({
    name: "",
    phone: "",
    email: "",
    cpf: "",
    birthday: "",
    address: {
      zip_code: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
    },
  });
  const [bodyTwo, setBodyTwo] = useState({
    name: "",
    phone: "",
    email: "",
    cpf: "",
    birthday: "",
    address: {
      zip_code: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
    },
  });
  const [bodyThree, setBodyThree] = useState({
    name: "",
    phone: "",
    email: "",
    cpf: "",
    birthday: "",
    address: {
      zip_code: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
    },
  });
  const validationCard = !hasEmptyFields(payment);

  const changeFinalBody = () => {
    let body;
    if (dependentsSelected === 2) {
      body = {
        principal: {
          name: data?.name,
          phone: data?.phone.replace(/[^0-9s]/g, ""),
          email: data?.email,
          cpf: data?.cpf.replace(/[^0-9s]/g, ""),
          birthday: data?.birthday.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          ),
          address: {
            zip_code: data?.address?.zip_code.replace(/[^0-9s]/g, ""),
            state: data?.address?.state,
            city: data?.address?.city,
            street: data?.address?.street,
            number: data?.address?.number,
            neighborhood: data?.address?.neighborhood,
            complement: data?.address?.complement,
          },
          payment: {
            card_holder_name: payment?.card_holder_name,
            card_brand: payment?.card_brand,
            card_cvv: payment?.card_cvv,
            card_year_expiration: payment?.card_year_expiration,
            card_month_expiration: payment?.card_month_expiration,
            card_number: payment?.card_number.replace(/[^0-9s]/g, ""),
            plan_code: payment?.plan_code,
            manager_code: manager_code,
          },
        },
        dependentes: [
          {
            name: bodyOne?.name,
            phone: bodyOne?.phone.replace(/[^0-9s]/g, ""),
            email: bodyOne?.email,
            cpf: bodyOne?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyOne?.birthday.replace(
              /(\d{2})\/(\d{2})\/(\d{4})/,
              "$3-$2-$1"
            ),
            address: {
              zip_code: bodyOne?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyOne?.address?.state,
              city: bodyOne?.address?.city,
              street: bodyOne?.address?.street,
              number: bodyOne?.address?.number,
              neighborhood: bodyOne?.address?.neighborhood,
              complement: bodyOne?.address?.complement,
            },
          },
        ],
      };
    }
    if (dependentsSelected === 3) {
      body = {
        principal: {
          name: data?.name,
          phone: data?.phone.replace(/[^0-9s]/g, ""),
          email: data?.email,
          cpf: data?.cpf.replace(/[^0-9s]/g, ""),
          birthday: data?.birthday.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          ),
          address: {
            zip_code: data?.address?.zip_code.replace(/[^0-9s]/g, ""),
            state: data?.address?.state,
            city: data?.address?.city,
            street: data?.address?.street,
            number: data?.address?.number,
            neighborhood: data?.address?.neighborhood,
            complement: data?.address?.complement,
          },
          payment: {
            card_holder_name: payment?.card_holder_name,
            card_brand: payment?.card_brand,
            card_cvv: payment?.card_cvv,
            card_year_expiration: payment?.card_year_expiration,
            card_month_expiration: payment?.card_month_expiration,
            card_number: payment?.card_number.replace(/[^0-9s]/g, ""),
            plan_code: payment?.plan_code,
            manager_code: manager_code,
          },
        },
        dependentes: [
          {
            name: bodyOne?.name,
            phone: bodyOne?.phone.replace(/[^0-9s]/g, ""),
            email: bodyOne?.email,
            cpf: bodyOne?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyOne?.birthday.replace(
              /(\d{2})\/(\d{2})\/(\d{4})/,
              "$3-$2-$1"
            ),
            address: {
              zip_code: bodyOne?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyOne?.address?.state,
              city: bodyOne?.address?.city,
              street: bodyOne?.address?.street,
              number: bodyOne?.address?.number,
              neighborhood: bodyOne?.address?.neighborhood,
              complement: bodyOne?.address?.complement,
            },
          },
          {
            name: bodyTwo?.name,
            phone: bodyTwo?.phone.replace(/[^0-9s]/g, ""),
            email: bodyTwo?.email,
            cpf: bodyTwo?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyTwo?.birthday.replace(
              /(\d{2})\/(\d{2})\/(\d{4})/,
              "$3-$2-$1"
            ),
            address: {
              zip_code: bodyTwo?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyTwo?.address?.state,
              city: bodyTwo?.address?.city,
              street: bodyTwo?.address?.street,
              number: bodyTwo?.address?.number,
              neighborhood: bodyTwo?.address?.neighborhood,
              complement: bodyTwo?.address?.complement,
            },
          },
        ],
      };
    }
    if (dependentsSelected === 4) {
      body = {
        principal: {
          name: data?.name,
          phone: data?.phone.replace(/[^0-9s]/g, ""),
          email: data?.email,
          cpf: data?.cpf.replace(/[^0-9s]/g, ""),
          birthday: data?.birthday,
          address: {
            zip_code: data?.address?.zip_code.replace(/[^0-9s]/g, ""),
            state: data?.address?.state,
            city: data?.address?.city,
            street: data?.address?.street,
            number: data?.address?.number,
            neighborhood: data?.address?.neighborhood,
            complement: data?.address?.complement,
          },
          payment: {
            card_holder_name: payment?.card_holder_name,
            card_brand: payment?.card_brand,
            card_cvv: payment?.card_cvv,
            card_year_expiration: payment?.card_year_expiration,
            card_month_expiration: payment?.card_month_expiration,
            card_number: payment?.card_number.replace(/[^0-9s]/g, ""),
            plan_code: payment?.plan_code,
            manager_code: manager_code,
          },
        },
        dependentes: [
          {
            name: bodyOne?.name,
            phone: bodyOne?.phone.replace(/[^0-9s]/g, ""),
            email: bodyOne?.email,
            cpf: bodyOne?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyOne?.birthday,
            address: {
              zip_code: bodyOne?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyOne?.address?.state,
              city: bodyOne?.address?.city,
              street: bodyOne?.address?.street,
              number: bodyOne?.address?.number,
              neighborhood: bodyOne?.address?.neighborhood,
              complement: bodyOne?.address?.complement,
            },
          },
          {
            name: bodyTwo?.name,
            phone: bodyTwo?.phone.replace(/[^0-9s]/g, ""),
            email: bodyTwo?.email,
            cpf: bodyTwo?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyTwo?.birthday,
            address: {
              zip_code: bodyTwo?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyTwo?.address?.state,
              city: bodyTwo?.address?.city,
              street: bodyTwo?.address?.street,
              number: bodyTwo?.address?.number,
              neighborhood: bodyTwo?.address?.neighborhood,
              complement: bodyTwo?.address?.complement,
            },
          },
          {
            name: bodyThree?.name,
            phone: bodyThree?.phone.replace(/[^0-9s]/g, ""),
            email: bodyThree?.email,
            cpf: bodyThree?.cpf.replace(/[^0-9s]/g, ""),
            birthday: bodyThree?.birthday,
            address: {
              zip_code: bodyThree?.address?.zip_code.replace(/[^0-9s]/g, ""),
              state: bodyThree?.address?.state,
              city: bodyThree?.address?.city,
              street: bodyThree?.address?.street,
              number: bodyThree?.address?.number,
              neighborhood: bodyThree?.address?.neighborhood,
              complement: bodyThree?.address?.complement,
            },
          },
        ],
      };
    }
    return body;
  };

  function hasEmptyFields(data: any) {
    for (const key in data) {
      if (
        key !== "manager_code" &&
        key !== "plan_code" &&
        key !== "card_brand" &&
        typeof data[key] === "string" &&
        data[key].trim() === ""
      ) {
        return true;
      }
    }
    return false;
  }

  function changePlan() {
    if (dependentsSelected === 2) {
      setPayment({ ...payment, plan_code: "plan1" });
    }
    if (dependentsSelected === 3) {
      setPayment({ ...payment, plan_code: "plan2" });
    }
    if (dependentsSelected === 4) {
      setPayment({ ...payment, plan_code: "plan3" });
    }
  }

  useEffect(() => {
    changePlan();
  }, [dependentsSelected]);

  function identifyCardBrand(cardNumber: any) {
    const cardNumberString = cardNumber?.toString();
    if (cardNumberString.startsWith("4")) {
      setPayment({ ...payment, card_brand: "visa" });
      return;
    } else if (cardNumberString.startsWith("5")) {
      setPayment({ ...payment, card_brand: "MasterCard" });
      return;
    } else if (cardNumberString.match(/^3[47]/)) {
      setPayment({ ...payment, card_brand: "American Express" });
      return;
    } else if (cardNumberString.startsWith("6")) {
      setPayment({ ...payment, card_brand: "Discover" });
      return;
    } else {
      setPayment({ ...payment, card_brand: "Bandeira desconhecida" });
      return;
    }
  }

  async function validateBody() {
    if (
      dependentsSelected === 2 &&
      validationFormOne === true &&
      validationCard === true &&
      acceptTerms
    ) {
      setDisabled(false);
    } else if (
      dependentsSelected === 3 &&
      validationFormOne === true &&
      validationFormTwo === true &&
      validationCard === true &&
      acceptTerms
    ) {
      setDisabled(false);
    } else if (
      dependentsSelected === 4 &&
      validationFormOne === true &&
      validationFormTwo === true &&
      validationFormThree === true &&
      validationCard === true &&
      acceptTerms
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateBody();
  }, [
    dependentsSelected,
    validationFormOne,
    validationFormTwo,
    validationFormThree,
    validationCard,
    acceptTerms,
  ]);

  useEffect(() => {
    if (payment?.card_number !== "") {
      identifyCardBrand(payment?.card_number);
    }
  }, [payment?.card_number]);

  async function HandleSubmit() {
    setLoading(true);
    await changePlan();
    const body = await changeFinalBody();

    try {
      const { data } = await UsersService.create(body);
      if (data.status === true) {
        navigate("/pagamento", {
          state: { data: body, transaction: data?.transaction_code },
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: false,
        timer: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleCheckboxChange = () => {
    setAcceptTerms(!acceptTerms);
  };

  return (
    <>
      {openSummary ? (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Paper elevation={4}>
            <RequestedSummary
              setOpenSummary={setOpenSummary}
              holderName={data?.name ?? ""}
              holderCpf={data?.cpf ?? ""}
              holderPhone={data?.phone ?? ""}
              holderEmail={data?.email ?? ""}
              holderAddress={data?.address.street ?? ""}
              holderCep={data?.address.zip_code ?? ""}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
            />
          </Paper>
        </Slide>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.wrapperInfo}>
              <Box className={classes.containerSteps}>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Box>
                      <CheckIcon
                        style={{ color: theme.palette.primary.main }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Cadastro
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circle}>
                    <Typography className={classes.circleText}>2</Typography>
                  </Box>
                  <Typography className={classes.textStep}>
                    Forma de pagamento
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Typography className={classes.circleTextOutlined}>
                      3
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Comprovante de ativação
                  </Typography>
                </Box>
              </Box>
              <img src={logo} alt="Logo Dr Saude" className={classes.logo} />
              <Box
                className={classes.retangleOrder}
                onClick={() => setOpenSummary(true)}
              >
                <Typography className={classes.textOrder}>
                  Resumo do pedido
                </Typography>
                <KeyboardArrowDownIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </Box>
            </Box>

            <Box className={classes.containerEnvinroment}>
              <Typography className={classes.textSecurity}>
                <HttpsIcon
                  style={{ width: "16px", color: theme.palette.text.secondary }}
                />
                ambiente seguro
              </Typography>
            </Box>
            <Box className={classes.wrapperStep}>
              <img src={mobileSecondStep} alt="Etapa da tela" />
            </Box>
          </Box>
          <Typography className={classes.title}>
            Informações do Cartão de Crédito
          </Typography>
          <Box className={classes.containerColumns}>
            <Box className={classes.box}>
              <Typography className={classes.boxTitle}>
                Informações do Cartão de Crédito
              </Typography>
              <Box className={classes.separator} />
              <Box>
                <>
                  <label className={classes.label}>
                    Nome titular do cartão
                  </label>
                  <TextField
                    name="name"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ marginTop: "5px", marginBottom: "20px" }}
                    value={payment?.card_holder_name}
                    onChange={(e) =>
                      setPayment({
                        ...payment,
                        card_holder_name: e.target.value,
                      })
                    }
                  />
                  <label className={classes.label}>Número do cartão</label>
                  <TextField
                    name="cardNumber"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      formatCreditCardNumber(payment?.card_number) ??
                      payment?.card_number
                    }
                    onChange={(e) => {
                      setPayment({ ...payment, card_number: e.target.value });
                    }}
                    inputProps={{ maxLength: 19 }}
                  />
                  <Box className={classes.containerCards}>
                    <img
                      alt="Bandeiras do cartao de credito"
                      src={flagCards}
                      className={classes.imgCards}
                    />
                  </Box>
                  <Box className={classes.divRow}>
                    <Box className={classes.labelAndInput}>
                      <label className={classes.label}>Mês de expiração</label>
                      <TextField
                        name="expired"
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px", marginBottom: "20px" }}
                        value={payment?.card_month_expiration}
                        onChange={(e) =>
                          setPayment({
                            ...payment,
                            card_month_expiration: e.target.value,
                          })
                        }
                        inputProps={{ maxLength: 2 }}
                      />
                    </Box>
                    <Box className={classes.labelAndInput}>
                      <label className={classes.label}>Ano de expiração</label>

                      <TextField
                        name="expired"
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px", marginBottom: "20px" }}
                        value={payment?.card_year_expiration}
                        onChange={(e) =>
                          setPayment({
                            ...payment,
                            card_year_expiration: e.target.value,
                          })
                        }
                        inputProps={{ maxLength: 2 }}
                      />
                    </Box>
                    <Box className={classes.labelAndInput}>
                      <label className={classes.label}>CVV</label>
                      <TextField
                        name="cvv"
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px", marginBottom: "20px" }}
                        value={payment?.card_cvv}
                        onChange={(e) =>
                          setPayment({ ...payment, card_cvv: e.target.value })
                        }
                        inputProps={{ maxLength: 4 }}
                      />
                    </Box>
                  </Box>
                </>
              </Box>
              <Box className={classes.planOptions}>
                <Typography className={classes.boxTitle}>
                  Opções de planos
                </Typography>
                <Box className={classes.separator} />
                <Box className={classes.containerCardsDependents}>
                  {plans?.map(({ dependents, value }) => {
                    return (
                      <Card
                        dependents={dependents}
                        setDependentsSelected={setDependentsSelected}
                        value={value}
                        dependentsSelected={dependentsSelected}
                        setValueSelected={setValueSelected}
                      />
                    );
                  })}
                </Box>
                <Box className={classes.containerCardsDependentsMobile}>
                  <Slider {...settings}>
                    {plans?.map(({ dependents, value }) => {
                      return (
                        <Card
                          dependents={dependents}
                          setDependentsSelected={setDependentsSelected}
                          value={value}
                          dependentsSelected={dependentsSelected}
                          setValueSelected={setValueSelected}
                        />
                      );
                    })}
                  </Slider>
                </Box>
                <Box className={classes.containerAccordions}>
                  <AccordionDependent
                    body={bodyOne}
                    setBody={setBodyOne}
                    setValidationFormOne={setValidationFormOne}
                  />
                  {dependentsSelected >= 3 && (
                    <AccordionDependentTwo
                      body={bodyTwo}
                      setBody={setBodyTwo}
                      setValidationFormTwo={setValidationFormTwo}
                    />
                  )}
                  {dependentsSelected === 4 && (
                    <AccordionDependentThree
                      body={bodyThree}
                      setBody={setBodyThree}
                      setValidationFormThree={setValidationFormThree}
                    />
                  )}
                </Box>
                <Box className={classes.containerCheckbox}>
                  <input
                    type="checkbox"
                    checked={acceptTerms}
                    onChange={handleCheckboxChange}
                  />
                  <label>Li e aceito os </label>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    termos de uso e política de privacidade.
                  </a>
                </Box>

                <Box className={classes.containerButton}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      color: "#FFF",
                      fontSize: "18px",
                      marginTop: "30px",
                    }}
                    className={classes.button}
                    disabled={disabled || loading}
                    onClick={HandleSubmit}
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>
            </Box>
            <DesktopInformations
              setOpenSummary={setOpenSummary}
              holderName={data?.name ?? ""}
              holderCpf={data?.cpf ?? ""}
              holderPhone={data?.phone ?? ""}
              holderEmail={data?.email ?? ""}
              holderAddress={data?.address.street ?? ""}
              holderCep={data?.address.zip_code ?? ""}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
