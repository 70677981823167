import { Box, Divider, Typography } from "@mui/material";
import { InformationsProps } from "../../types/InformationsTypes";
import { useStyles } from "./styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { CpfMask } from "../../utils/masks";
export function DesktopInformations({
  holderName,
  holderCpf,
  holderPhone,
  holderEmail,
  holderAddress,
  holderCep,
  dependents,
  plan,
  paymentType,
  paymentDate,
  total,
  value
}: InformationsProps) {
  const classes = useStyles();
  const today = new Date();
  const day = today.getDate().toString().padStart(2, '0');
  const navigate = useNavigate();
  return (
    <Box className={classes.containerDesktopInformation}>
      <Typography className={classes.titleHolder}>Resumo do pedido</Typography>
      <Divider />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.wrapperInfo}>
          <Typography className={classes.titleHolder}>Titular</Typography>
          <Box className={classes.containerIcon} onClick={() => navigate('/')}>

          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
          {holderName || "Não informado"} (CPF: {CpfMask(holderCpf) || "Não informado"})
        </Typography>
        <Box className={classes.containerRow}>
          <Typography className={classes.infoHolder}>
            {holderPhone || "Não informado"}
          </Typography>
          <Typography className={classes.infoHolder}>
            {holderEmail || "Não informado"}
          </Typography>
        </Box>
        <Typography className={classes.infoHolder}>
          {holderAddress || "Não informado"}
        </Typography>
        <Typography className={classes.infoHolder}>
          CEP: {holderCep || "Não informado"}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.wrapperInfo}>
          <Typography className={classes.titleHolder}>
            Informações do plano
          </Typography>
          <Box className={classes.containerIcon} onClick={() => navigate('/registro')}>
          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
          {(dependents ?? '2') + ` pessoas (R$ ${value ?? '29.90'})` || "Não informado"}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.containerInfoHolder}>
        <Box className={classes.wrapperInfo}>
          <Typography className={classes.titleHolder}>
            Forma de pagamento
          </Typography>
          <Box className={classes.containerIcon} onClick={() => navigate('/registro')}>
          </Box>
        </Box>
        <Typography className={classes.infoHolder}>
          Pagamento: Mensal (recorrente)
        </Typography>
        <Typography className={classes.infoHolder}>
          Tipo de pagamento: Cartão de crédito

        </Typography>

        <Typography className={classes.infoHolder}>
          Data do lançamento: Mensal dia {day}
        </Typography>
        <Typography className={classes.observation}>
          * Caso o cartão seja cancelado ou renovado, por favor entre em contato
          com a nossa equipe para atualização financeira, caso contrário seu
          plano será cancelado automaticamente
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box
        className={classes.containerRow}
        style={{ justifyContent: "space-between" }}
      >
        <Typography
          className={classes.titleHolder}
          style={{ fontSize: "20px" }}
        >
          Total:
        </Typography>
        <Typography
          className={classes.titleHolder}
          style={{ fontSize: "35px" }}
        >
          R$ {value ?? '29,90'}
        </Typography>
      </Box>
    </Box>
  );
}
