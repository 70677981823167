import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFF",
    position: "relative",
    padding: "15px 15px",
    zIndex: 999,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 25,
    fontWeight: 600,
  },
  containerInfoHolder: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    gap: "5px",
  },
  titleHolder: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 17,
      fontWeight: 600,
    },
  },
  infoHolder: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 15,
      fontWeight: 500,
    },
  },
  divider: {
    marginTop: "15px",
  },
  containerRow: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  observation: {
    "&.MuiTypography-root": {
      color: "rgba(80, 95, 113, 0.60)",
      fontSize: 9,
      fontWeight: 500,
      margin: "15px 0px 10px 0px",
    },
  },
  containerIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  editText: {
    "&.MuiTypography-root": {
      color: theme.palette.text.primary,
      fontSize: 15,
      fontWeight: 700,
    },
  },
}));
